export enum EScanTableColumns {
  Text = 'text',
  Number = 'number',
  File = 'file',
  Readonly = 'readonly',
  TrueFalse = 'true/false',
}

export interface IScanTableColumn {
  name: string;
  scannerPosition: number;
  mainTablePosition: number;
  type: EScanTableColumns;
  disabled: boolean;
  id: number;
}

export interface IScanTable {
  content: any[];
  columns: IScanTableColumn[];
  scanColumns: IScanTableColumn[];
  settings: any;
  title: string;
  type?: string;
}

export interface IScanTableResponse extends IScanTable {
  id: number;
  createdAt: string;
  updatetAt: string;
  locale: string;
}
