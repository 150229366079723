import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { HttpGeneralService } from '../../../../core/services/http-general/http-general.service';

@Injectable({
  providedIn: 'root',
})
export class ScanService {
  pipple$: Subject<string> = new Subject();
  id$: BehaviorSubject<string> = new BehaviorSubject<string>('0');

  constructor(
    private db: NgxIndexedDBService,
    private http: HttpGeneralService,
  ) {}

  getField(object: any, path: string[]): any {
    path.forEach((el) => {
      object = object[el];
    });

    return object;
  }

  updateField(object: any, path: string[], value: any) {
    let a: any = [];
    a.push(object);
    path.forEach((el) => {
      a.push(object[el]);
      object = object[el];
    });
    a[a.length - 1] = value;
    a.reverse().forEach((el: any, index: number) => {
      if (index < a.length - 2) {
        a[index + 1][path[path.length - index - 1]] = el;
      }
    });
    return JSON.parse(JSON.stringify(a[a.length - 1]));
  }

  setId(id: string) {
    this.id$.next(id);
  }

  takeScanPhoto(photo: any): Observable<any> {
    return this.http.post('users/images-64', photo);
  }

  takeScanVideo(video: any): Observable<any> {
    return this.http.post('users/videos-64', video);
  }
}
