import {
  EScanTableColumns,
  IScanTable,
  IScanTableColumn,
  IScanTableResponse,
} from '../interfaces/scan/scan-table.interface';

export const defaultScanTableColumns: IScanTableColumn[] = [
  {
    name: 'id',
    scannerPosition: 1,
    mainTablePosition: 1,
    id: 0,
    disabled: true,
    type: EScanTableColumns.Readonly,
  },
  {
    name: 'count',
    scannerPosition: 2,
    mainTablePosition: 2,
    id: 1,
    disabled: true,
    type: EScanTableColumns.Number,
  },
  {
    name: 'code',
    scannerPosition: 3,
    mainTablePosition: 3,
    id: 2,
    disabled: true,
    type: EScanTableColumns.Text,
  },
  {
    name: 'title',
    scannerPosition: 4,
    mainTablePosition: 4,
    id: 3,
    disabled: true,
    type: EScanTableColumns.Text,
  },
  {
    name: 'description',
    scannerPosition: 5,
    mainTablePosition: 5,
    id: 4,
    disabled: true,
    type: EScanTableColumns.Text,
  },
  {
    name: 'photo',
    scannerPosition: 6,
    mainTablePosition: 6,
    id: 5,
    disabled: true,
    type: EScanTableColumns.File,
  },
  {
    name: 'video',
    scannerPosition: 7,
    mainTablePosition: 7,
    id: 6,
    disabled: true,
    type: EScanTableColumns.File,
  },
  {
    name: 'qr_url',
    scannerPosition: 8,
    mainTablePosition: 8,
    id: 7,
    disabled: true,
    type: EScanTableColumns.Readonly,
  },
];

export const defaultScanTableResponse: IScanTableResponse = {
  content: [{ id: 0 }],
  columns: defaultScanTableColumns,
  scanColumns: [],
  settings: {},
  id: -1,
  locale: '',
  createdAt: '',
  updatetAt: '',
  title: '',
};

export const defaultScanTable: IScanTable = {
  content: [],
  columns: defaultScanTableColumns,
  scanColumns: [],
  settings: {
    maxRawsToShow: 1,
    notMergeQuantities: false,
  },
  title: '',
};

export const defaultPosListTable: IScanTable = {
  content: [],
  columns: [
    {
      id: 0,
      name: 'number',
      type: EScanTableColumns.Text,
      disabled: true,
      scannerPosition: 1,
      mainTablePosition: 1,
    },
    {
      id: 1,
      name: 'date',
      type: EScanTableColumns.Text,
      disabled: true,
      scannerPosition: 2,
      mainTablePosition: 2,
    },
    {
      id: 2,
      name: 'scan',
      type: EScanTableColumns.Readonly,
      disabled: true,
      scannerPosition: 3,
      mainTablePosition: 3,
    },
  ],
  scanColumns: [],
  settings: {
    maxRawsToShow: 1,
    lastNo: 1,
    type: 'posList',
  },
  title: '',
};

export const defaultScanPosTable = {
  columns: [
    {
      id: 0,
      name: 'code',
      type: 'text',
      disabled: true,
      scannerPosition: 1,
      mainTablePosition: 1,
    },
    {
      id: 1,
      name: 'title',
      type: 'text',
      disabled: true,
      scannerPosition: 2,
      mainTablePosition: 2,
    },
    {
      id: 2,
      name: 'count',
      type: 'number',
      disabled: true,
      scannerPosition: 3,
      mainTablePosition: 3,
    },
    {
      id: 3,
      name: 'price',
      type: 'number',
      disabled: true,
      scannerPosition: 4,
      mainTablePosition: 4,
    },
    {
      id: 4,
      name: 'sum',
      type: 'number',
      disabled: true,
      scannerPosition: 5,
      mainTablePosition: 5,
    },
    {
      id: 5,
      name: 'cash',
      type: 'true/false',
      disabled: true,
      scannerPosition: 6,
      mainTablePosition: 6,
    },
    {
      id: 6,
      name: 'transfer to account',
      type: 'true/false',
      disabled: true,
      scannerPosition: 7,
      mainTablePosition: 7,
    },
    {
      id: 7,
      name: 'discount',
      type: 'number',
      disabled: true,
      scannerPosition: 8,
      mainTablePosition: 8,
    },
    {
      id: 8,
      name: 'total',
      type: 'readonly',
      disabled: true,
      scannerPosition: 9,
      mainTablePosition: 9,
    },
    {
      id: 9,
      name: 'photo',
      type: 'file',
      disabled: true,
      scannerPosition: 10,
      mainTablePosition: 10,
    },
  ],
  content: [],
  settings: {
    lastId: 0,
    maxRawsToShow: 1,
    type: 'pos',
    notMergeQuantities: true,
  },
  scanColumns: [],
};
