import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { catchError, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { IScanTable, IScanTableResponse } from '../../interfaces/scan/scan-table.interface';
import { HttpGeneralService } from '../../../core/services/http-general/http-general.service';
import { IAppState } from '../../../core/store/state/app.state';
import { Store } from '@ngrx/store';
import { AddTable, RemoveTable, UpdateTable } from '../../store/scan/actions/scan-tables.actions';
import { selectScanTable } from '../../store/scan/selectors/scan-tables.selector';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { defaultScanTable } from '../../const/scan-table-default';
import { isPlatformServer } from '@angular/common';
import { WINDOW } from '../../../core/injection-tokens/window.token';

@Injectable({
  providedIn: 'root',
})
export class ScanTablesService {
  private isServer = isPlatformServer(inject(PLATFORM_ID));
  private window = inject(WINDOW);

  constructor(
    private http: HttpGeneralService,
    private store: Store<IAppState>,
    private db: NgxIndexedDBService,
  ) {}

  getTables(): Observable<any> {
    return this.http.get('users/tables').pipe(
      map((res) => {
        return res?.data;
      }),
      catchError((er) => {
        if (this.isServer) {
          return of();
        }
        return this.db
          .add('list', { ...defaultScanTable, title: 'New table' + Date.now(), isOffline: true })
          .pipe(
            map((table) => [table]),
            take(1),
          );
      }),
      tap((res) => {
        res.forEach((table: IScanTableResponse) => {
          this.store.dispatch(new AddTable(table));
        });
      }),
    );
  }

  createTable(table: IScanTable): Observable<any> {
    if (this.isServer) {
      return of();
    }
    if (!this.window.navigator.onLine) {
      return this.db.add('list', { ...table, isOffline: true, locale: 'en' }).pipe(
        map((table) => {
          return { data: table };
        }),
        take(1),
      );
    } else {
      return this.http.post('users/tables', { data: table }).pipe(
        tap((res) => {
          this.store.dispatch(new AddTable(res.data));
        }),
      );
    }
  }

  updateTable(id: number, table: any): Observable<any> {
    if (this.isServer) {
      return of();
    }
    if (!this.window.navigator.onLine) {
      return this.db.clear('list').pipe(
        take(1),
        switchMap(() => {
          const tableId = table.id;
          return this.db.add('list', table).pipe(
            map((table) => ({ data: { ...table, id: tableId } })),
            take(1),
          );
        }),
      );
    } else {
      return this.http.put(`users/tables/${id}`, { data: table }).pipe(
        tap((res) => {
          this.store.dispatch(new UpdateTable(res.data));
        }),
      );
    }
  }

  getTable(id: number): Observable<any> {
    if (this.isServer) {
      return of();
    }
    return this.store.select(selectScanTable, id).pipe(
      take(1),
      switchMap((res) => {
        if (res) {
          return of(res);
        } else {
          if (!this.window.navigator.onLine) {
            return this.db.getByID('list', id).pipe(take(1));
          } else {
            return this.http.get(`users/tables/${id}`).pipe(
              map((res) => res.data),
              tap((res) => {
                this.store.dispatch(new AddTable(res));
              }),
            );
          }
        }
      }),
    );
  }

  deleteTable(id: number): Observable<any> {
    this.store.dispatch(new RemoveTable(id));
    return this.http.delete(`users/tables/${id}`);
  }
}
