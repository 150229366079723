import { Injectable } from '@angular/core';
import { HttpGeneralService } from '../../../core/services/http-general/http-general.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShortLinkService {
  constructor(private http: HttpGeneralService) {}

  createShortLink(settings: any): Observable<any> {
    if (!navigator.onLine) {
      return of({ data: { uuid: JSON.stringify(settings) } });
    } else {
      return this.http.post('users/short-links', { settings });
    }
  }
}
