import { IAppState } from '../../../../core/store/state/app.state';
import { createSelector } from '@ngrx/store';
import { IScanTable } from '../../../interfaces/scan/scan-table.interface';

const selectScanTables = (state: IAppState) => state.scanTables;

export const selectScanTablesList = createSelector(
  selectScanTables,
  (state: IScanTable[]) => state,
);

export const selectScanTable = createSelector(
  selectScanTables,
  (state: IScanTable[], id: number) => state[id],
);
