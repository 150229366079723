import { Action } from '@ngrx/store';
import { IScanTableResponse } from '../../../interfaces/scan/scan-table.interface';

export enum EScanTablesActions {
  AddTable = '[Scan] Add table',
  RemoveTable = '[Scan] Remove table',
  UpdateTable = '[Scan] Update table',
  RemoveAllTables = '[Scan] Remove all tables',
}

export class AddTable implements Action {
  public readonly type = EScanTablesActions.AddTable;
  constructor(public table: IScanTableResponse) {}
}

export class RemoveTable implements Action {
  public readonly type = EScanTablesActions.RemoveTable;
  constructor(public id: number) {}
}

export class UpdateTable implements Action {
  public readonly type = EScanTablesActions.UpdateTable;
  constructor(public table: IScanTableResponse) {}
}

export class RemoveAllTables implements Action {
  public readonly type = EScanTablesActions.RemoveAllTables;
  constructor() {}
}

export type ScanTableActions = AddTable | RemoveTable | UpdateTable | RemoveAllTables;
